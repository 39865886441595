import React from 'react'

import { Menu } from '@mantine/core' // assuming you are using Mantine

import Button from 'src/components/Buttons/Button/Button'

type MenuItem = {
  label: string
  hidden?: boolean
  onClick: () => void
  disabled?: boolean
}

interface ActionsMenuProps {
  text: string
  items: MenuItem[]
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
}

const ActionsMenu = ({
  text,
  items,
  leftIcon,
  rightIcon,
}: ActionsMenuProps) => {
  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <Button
          type="button"
          text={text}
          lefticon={leftIcon}
          righticon={rightIcon}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {items
          .filter((item) => !item.hidden)
          .map((item, index) => (
            <Menu.Item
              key={index}
              onClick={item.onClick}
              disabled={item.disabled === undefined ? false : item.disabled}
            >
              {item.label}
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  )
}

export default ActionsMenu
