import { useEffect, useState } from 'react'

import { DatePickerInput } from '@mantine/dates'

import IconCalendar from 'src/icons/IconCalendar'
export interface DatePickerFilterProps {
  interval?: { gte: Date; lte: Date }
  onChange: ({ from, to }: { from: Date; to: Date }) => void
}

export const DatePickerFilter = ({
  interval,
  onChange,
}: DatePickerFilterProps) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([
    interval?.gte ?? null,
    interval?.lte ?? null,
  ])

  // Effect to update value when interval filter is cleared
  useEffect(() => {
    if (
      interval?.gte === null &&
      interval?.lte === null &&
      value[0] !== null &&
      value[1] !== null
    ) {
      setValue([null, null])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval])

  useEffect(() => {
    const from = value[0] ? new Date(value[0]) : null
    const to = value[1] ? new Date(value[1]) : null

    if (from) {
      from.setHours(0, 0, 0, 0) // Set from date to the start of the day
    }

    if (to) {
      to.setHours(23, 59, 59, 999) // Set to date to the end of the day
    }

    if (from && to) {
      onChange({ from, to })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <DatePickerInput
        type="range"
        clearable
        leftSection={
          <IconCalendar className="h-4 w-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
        }
        valueFormat="MM/DD/YYYY"
        classNames={{
          label: 'text-doubleNickel-gray-600',
          input:
            'text-xs rounded-lg drop-shadow-sm font-semibold border border-doubleNickel-gray-300 bg-doubleNickel-white text-doubleNickel-gray-700 hover:bg-doubleNickel-gray-50',
        }}
        allowSingleDateInRange={true}
        placeholder={
          <div className="text-xs font-semibold text-doubleNickel-gray-600">
            Select dates
          </div>
        }
        value={value}
        onChange={setValue}
      />
    </>
  )
}

export default DatePickerFilter
